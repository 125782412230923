var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"col-12 pd-0",staticStyle:{"padding":"0"}},[_c('div',[_vm._l((_vm.row.videos_key),function(i){return _c('div',{key:'i1' + i,staticClass:"row",class:i == 0 ? 'hidden' : '',staticStyle:{"border-bottom":"1px solid #e1e1e1","margin-bottom":"10px"},attrs:{"id":'frm1_' + i}},[_c('div',{staticClass:"col-12 form-group"},[_c('label',{class:_vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'txt-left'},[_vm._v(" "+_vm._s(_vm.$t("view.title"))+" ("+_vm._s(_vm.$t("app.arabic"))+") ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.videos_title[i]),expression:"row.videos_title[i]"}],staticClass:"form-control",attrs:{"id":"input","type":"text"},domProps:{"value":(_vm.row.videos_title[i])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.row.videos_title, i, $event.target.value)},_vm.onInputChange]}})]),_c('div',{staticClass:"col-12 form-group"},[_c('label',{class:_vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'txt-left'},[_vm._v(" "+_vm._s(_vm.$t("view.title"))+" ("+_vm._s(_vm.$t("app.english"))+") ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.videos_title_en[i]),expression:"row.videos_title_en[i]"}],staticClass:"form-control",attrs:{"id":"input","type":"text"},domProps:{"value":(_vm.row.videos_title_en[i])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.row.videos_title_en, i, $event.target.value)},_vm.onInputChange]}})]),_c('div',{staticClass:"col-10 form-group"},[_c('label',{class:_vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-15'
                  : 'txt-left'},[_vm._v(" "+_vm._s(_vm.$t("app.youtube"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row.videos_link[i]),expression:"row.videos_link[i]"}],staticClass:"form-control text-lowercase",attrs:{"id":"input","type":"text"},domProps:{"value":(_vm.row.videos_link[i])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.row.videos_link, i, $event.target.value)},_vm.onInputChange]}})]),_c('div',{staticClass:"col-2 form-group"},[_c('label',{staticStyle:{"margin-top":"18px"}}),(i != 0)?_c('button',{staticClass:"btn btn-danger btn-md",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeOption(i)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()]),_c('br')])}),_c('br'),_c('div',{staticStyle:{"text-align":"center"}},[_c('button',{staticClass:"btn btn-success waves-effect waves-light w-sm hacen_algeria font-size-15",attrs:{"type":"button"},on:{"click":function($event){return _vm.addMore()}}},[_c('span',{staticClass:"mdi mdi-plus-circle font-size-17"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("view.addMore"))+" ")])])])],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }