<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ label }}
      </h4>

      <div class="col-12 pd-0" style="padding: 0">
        <div>
          <div
            v-for="i in row.videos_key"
            :key="'i1' + i"
            :class="i == 0 ? 'hidden' : ''"
            :id="'frm1_' + i"
            class="row"
            style="border-bottom: 1px solid #e1e1e1; margin-bottom: 10px"
          >
            <div class="col-12 form-group" style="">
              <label
                :class="
                  $i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'txt-left'
                "
              >
                {{ $t("view.title") }} ({{ $t("app.arabic") }})
              </label>
              <input
                id="input"
                class="form-control"
                type="text"
                v-model="row.videos_title[i]"
                v-on:input="onInputChange"
              />
            </div>
            <div class="col-12 form-group" style="">
              <label
                :class="
                  $i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'txt-left'
                "
              >
                {{ $t("view.title") }} ({{ $t("app.english") }})
              </label>
              <input
                id="input"
                class="form-control"
                type="text"
                v-model="row.videos_title_en[i]"
                v-on:input="onInputChange"
              />
            </div>
            <div class="col-10 form-group" style="">
              <label
                :class="
                  $i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15'
                    : 'txt-left'
                "
              >
                {{ $t("app.youtube") }}
              </label>
              <input
                id="input"
                class="form-control text-lowercase"
                type="text"
                v-model="row.videos_link[i]"
                v-on:input="onInputChange"
              />
            </div>

            <div class="col-2 form-group" style="">
              <label style="margin-top: 18px"></label>
              <button
                v-if="i != 0"
                type="button"
                class="btn btn-danger btn-md"
                @click="removeOption(i)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <br />
          </div>

          <!-- Add More -->
          <br />
          <div style="text-align: center">
            <button
              type="button"
              class="btn btn-success waves-effect waves-light w-sm hacen_algeria font-size-15"
              @click="addMore()"
            >
              <span class="mdi mdi-plus-circle font-size-17"></span>
              <span> {{ $t("view.addMore") }} </span>
            </button>
          </div>
          <!-- End Add More -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardMultiInput",
  props: [
    "label",
    "videos",
    "videos_key",
    "videos_link",
    "videos_title",
    "videos_title_en",
  ],
  data() {
    return {
      row: {
        videos: this.$props.videos,
        videos_key: this.$props.videos_key,
        videos_link: this.$props.videos_link,
        videos_title: this.$props.videos_title,
        videos_title_en: this.$props.videos_title_en,
      },
    };
  },
  mounted() {
    this.onInputChange();
  },
  methods: {
    onInputChange() {
      // videos
      for (let i = 1; i <= this.row.videos_key; i++) {
        this.row.videos[i] = [
          {
            course_link: this.row.videos_link[i],
            title: this.row.videos_title[i],
            title_en: this.row.videos_title_en[i],
          },
        ];
      }

      const data = {
        videos: this.row.videos,
        videos_key: this.row.videos_key,
      };
      this.$emit("multiChange", data);
    },

    // open form
    addMore() {
      this.row.videos_key++;
    },

    // remote option
    removeOption(i) {
      document.querySelector("#frm1_" + i).remove();
      this.row.videos_link[i] = "";
      this.row.videos_title[i] = "";
      this.row.videos_title_en[i] = "";
      this.onInputChange();
    },
  },
};
</script>
